export var ajax = {
    ajaxFolder: '/ajax',
    ajaxdb: [],
    ajaxNow: null,
    ajaxStatus: false,
    controllerDb: {
        web: 'webController',
    },
    formEncode: function (obj) {
        var str = [], p;
        for (p in obj)
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        return str.join('&');
    },
    getUrl: function (data) {
        var result = {
            url: '',
        };
        var controller;
        if (typeof data === 'object') {
            controller = this.controllerDb[data[0]];
            result.url = this.ajaxFolder + '/' + controller + '.php';
            data.splice(0, 1);
            result.rules = data;
        }
        else
            result.url = this.ajaxFolder + data;
        return result;
    },
    send: function (url, data, callback) {
        var _this = this;
        var q, urlData;
        if (this.ajaxStatus === false) {
            this.ajaxStatus = true;
            this.ajaxNow = url;
            urlData = this.getUrl(url);
            if (urlData.rules) {
                if (!data)
                    data = {};
                data.rules = urlData.rules;
            }
            // q = fetch('http://localhost:8000' + urlData.url, {
            q = fetch(urlData.url, {
                method: 'post',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: { 'Content-type': 'application/x-www-form-urlencoded' },
                redirect: 'follow',
                referrer: 'no-referrer',
                body: this.formEncode(data),
            });
            if (callback) {
                q.then(function (response) {
                    _this.ajaxStatus = false;
                    return response.json();
                }).then(function (data) {
                    callback(data);
                    _this.ajaxQueue();
                });
            }
            else {
                q.then(function () {
                    _this.ajaxStatus = false;
                    _this.ajaxQueue();
                });
            }
        }
        else {
            if (this.ajaxNow !== url) {
                var allow = true;
                for (var row in this.ajaxdb) {
                    if (this.ajaxdb[row].url === url) {
                        allow = false;
                        break;
                    }
                }
                if (allow === true) {
                    this.ajaxdb.push({
                        url: url,
                        data: data,
                        callback: callback,
                    });
                }
            }
        }
    },
    ajaxQueue: function () {
        if (this.ajaxdb.length > 0) {
            if (this.ajaxdb[0].callback)
                this.send(this.ajaxdb[0].url, this.ajaxdb[0].data, this.ajaxdb[0].callback.bind(this.ajaxdb[0].callback));
            else
                this.send(this.ajaxdb[0].url, this.ajaxdb[0].data);
            this.ajaxdb.splice(0, 1);
        }
    },
};
