import $ from 'jquery';
import { ajax } from '@assets/js/classes/ajax.class';
import { Audio } from '@src/entities/Audio';
import { Pin } from '@src/entities/Pin';
import { Game } from '@src/entities/Game';
import { Box } from '@src/components/Box';
import { Pop } from '@src/components/Pop';
import boxStyles from '@src/components/Box/Box.module.css';
export var Main = {
    envelope: [10, 20, 30],
    award: null,
    status: '',
    randArr: [],
    init: function () {
        this.loadPage();
    },
    loadPage: function () {
        Game.$main.html("\n      <div class=\"main__title\"></div>\n      <div class=\"main__text\">\n        <p>".concat(Game.text.mainText || '', "</p>\n      </div>\n      <div class=\"main__btn-block\">\n        <div class=\"main__btn\">\n          <span>\u0418\u0413\u0420\u0410\u0422\u042C</span>\n        </div>\n        <div class=\"main__btn-text\">\n          <div class=\"text-grad\" data-text=\"\u0412\u044B\u0431\u0435\u0440\u0438 \u0448\u043A\u0430\u0442\u0443\u043B\u043A\u0443\">\n            <span>\u0412\u044B\u0431\u0435\u0440\u0438 \u0448\u043A\u0430\u0442\u0443\u043B\u043A\u0443</span>\n          </div>\n        </div>\n      </div>\n      <div class=\"box\">\n        <div class=\"box__absolute box__absolute--0\">\n          ").concat(Box({ sum: this.envelope[0] }), "\n        </div>\n        <div class=\"box__absolute box__absolute--1\">\n          ").concat(Box({ sum: this.envelope[1] }), "\n        </div>\n        <div class=\"box__absolute box__absolute--2\">\n          ").concat(Box({ sum: this.envelope[2] }), "\n        </div>\n      </div>\n    "));
        this.startPage();
    },
    startPage: function () {
        var _this = this;
        var $btnStart = Game.$main.find('.main__btn');
        var $box = Game.$main.find('.box');
        $btnStart.off('click').on('click', function () {
            $box.toggleClass("".concat(boxStyles.box__down), true);
            $btnStart.toggleClass('main__btn--disable', true);
            setTimeout(function () {
                Main.play();
            }, 1500);
            setTimeout(function () {
                document.querySelectorAll(".".concat(boxStyles.box__block)).forEach(function (item) {
                    var $this = $(item);
                    item.addEventListener('click', function () {
                        $this
                            .find(".".concat(boxStyles.box__sum))
                            .attr('data-text', _this.award)
                            .find('span')
                            .html(_this.award || '');
                        $this.toggleClass("".concat(boxStyles.box__block_up), true);
                        $this.toggleClass("".concat(boxStyles.box__disable), true);
                        $btnStart.toggleClass('main__btn--hide-text', true);
                        setTimeout(function () {
                            Audio.play('playerOpen');
                        }, 500);
                        setTimeout(function () {
                            _this.showAward();
                        }, 2000);
                    });
                });
            }, 3500);
        });
    },
    animBox: function () {
        var _this = this;
        Audio.play('playerMov');
        var $boxAbs = document.querySelectorAll('.box__absolute');
        var count = 0;
        var COUNT_END = 13;
        //Скрываем данные
        $boxAbs.forEach(function (el) {
            $(el).find('.box__sum').attr('data-text', 'none').find('span').html('none');
        });
        //Крутим
        var time = setInterval(function () {
            $boxAbs.forEach(function (item) {
                item.classList.remove('box__absolute--0');
                item.classList.remove('box__absolute--1');
                item.classList.remove('box__absolute--2');
            });
            _this.getRand();
            $boxAbs[0].classList.add("box__absolute--".concat(_this.randArr[0]));
            $boxAbs[1].classList.add("box__absolute--".concat(_this.randArr[1]));
            $boxAbs[2].classList.add("box__absolute--".concat(_this.randArr[2]));
            count++;
            if (count === COUNT_END) {
                clearInterval(time);
            }
        }, 200);
    },
    getRand: function () {
        var num = Math.floor(Math.random() * (2 + 1));
        if (this.randArr.length !== 3) {
            if (this.isTr(this.randArr, num)) {
                //@ts-ignore
                this.randArr.push(num);
            }
            return this.getRand();
        }
    },
    isTr: function (arr, number) {
        var ist = true;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] === number) {
                ist = false;
                break;
            }
        }
        return ist;
    },
    play: function () {
        var _this = this;
        ajax.send(['web', 'page', 'play'], {
            pin: Pin.mask.unmaskedValue,
        }, function (data) {
            if (data.error) {
                Pin.showPop(data.text);
            }
            if (data.award) {
                _this.award = data.award;
                _this.animBox();
            }
            document.querySelectorAll(".".concat(boxStyles.box__block)).forEach(function (item) {
                $(item).toggleClass("".concat(boxStyles.box__disable), false);
            });
        });
    },
    showAward: function () {
        var $pop = $(Pop({
            title: "\u0412\u0430\u0448 \u0432\u044B\u0438\u0433\u0440\u044B\u0448 <span>".concat(this.award, "</span>"),
            sup: 'Поздравляем!',
            win: true,
        }));
        $pop
            .find('.pop__btns')
            .html("<a href=\"".concat(this.chcPlayLink(), "\" class=\"pop__btn\">\u0417\u0430\u0431\u0440\u0430\u0442\u044C</a>"))
            .find('.pop__btn--ok')
            .on('click', function () {
            $pop.hide(200, function () {
                $pop.remove();
            });
        });
        Game.$main.append($pop);
    },
    chcPlayLink: function () {
        var params = {
            'menu_exit': '[[close]]',
            'game_exit': '[[menu]]',
            'screenW': 1024,
            'screenH': 768,
            'toolbar': [
                'quality',
                'resolution',
                'fullscreen',
            ],
            'back_url': 'https://chcplay.net/'
        };
        var pEncodedValue = encodeURIComponent(JSON.stringify(params)); // кодируем спецсимволы чтобы избежать некорректных запросов к серверу.
        return ('https://chcplay.net/?p=' + pEncodedValue);
    },
};
