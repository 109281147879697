import { sound } from '@pixi/sound';
import ClickAudio from '@assets/audio/click.mp3';
import BoxOpenAudio from '@assets/audio/box-open.mp3';
import MovingBoxAudio from '@assets/audio/moving-box.mp3';
export var Audio = {
    loadSounds: function () {
        sound.add('playerClick', ClickAudio);
        sound.add('playerOpen', BoxOpenAudio);
        sound.add('playerMov', MovingBoxAudio);
    },
    play: function (name) {
        sound.play(name);
    },
};
