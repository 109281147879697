import $ from 'jquery';
import IMask from 'imask';
import { Audio } from '@src/entities/Audio';
import { Game } from '@src/entities/Game';
import { PinBox } from '@src/components/PinBox';
import { Pop } from '@src/components/Pop';
export var Pin = {
    errors: {
        'Wrong ticket': 'Неверный код, проверьте и попробуйте ещё раз',
        'Error during check awards': 'Ошибка при поиске наград, попробуйте ещё раз',
        'Award calculating error': 'Ошибка при выборе награды, попробуйте ещё раз',
        'CLIENT_PROMOTION_ALREADY_START': 'На данный момент уже есть запущенная акция'
    },
    code: '',
    mask: null,
    $pinBox: null,
    init: function () {
        this.loadPage();
    },
    loadPage: function () {
        this.$pinBox = $(PinBox());
        Game.$main.append(this.$pinBox);
        Game.$main.append("\n      <div class=\"main__text\">\n        <p>".concat(Game.text.loginText || '', "</p>\n      </div>\n    "));
        this.$btn = this.$pinBox.find('button');
        var maskOptions = {
            mask: '0000',
        };
        this.mask = IMask(document.getElementById('code-input'), maskOptions);
        var $input = Game.$main.find('#code-input');
        $input.on('keydown', function (e) {
            if ((/^[0-9]$/.test(e.key) ||
                e.keyCode === 8 ||
                e.keyCode === 46 ||
                e.keyCode === 37 ||
                e.keyCode === 39) &&
                e.target.value.length <= 4) {
                return true;
            }
            e.preventDefault();
            return false;
        });
        //Запуск
        this.startPage();
        this.checkCalculator();
    },
    startPage: function () {
        var _this = this;
        var _a;
        (_a = this.$btn) === null || _a === void 0 ? void 0 : _a.on('click', function () {
            Audio.play('playerClick');
            Game.enter();
        });
        this.mask.on('accept', function () {
            _this.checkCalculator();
            Audio.play('playerClick');
        });
    },
    showPop: function (errorMessage) {
        var error = errorMessage && this.errors.hasOwnProperty(errorMessage)
            ? this.errors[errorMessage]
            : 'Ошибка связи с сервером.';
        var $pop = $(Pop({
            title: error,
        }));
        $pop
            .find('.pop__btns')
            .html("<div class=\"pop__btn pop__btn--ok\">\n          OK\n        </div>")
            .find('.pop__btn--ok')
            .on('click', function () {
            $pop.hide(200, function () {
                $pop.remove();
            });
        });
        Game.$main.append($pop);
    },
    checkCalculator: function () {
        this.$pinBox.find('button').prop('disabled', this.mask.unmaskedValue.length !== 4);
    },
};
