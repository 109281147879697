import ShadowSrc from '@assets/images/shadow.png';
import BoxBackSrc from '@assets/images/box_back.png';
import BoxFrontSrc from '@assets/images/box_front.png';
import BoxTopSrc from '@assets/images/box_top.png';
import GlowBoxSrc from '@assets/images/glow_box.png';
import GlowKSrc from '@assets/images/glow_k.png';
import styles from './Box.module.css';
export var Box = function (props) {
    var sum = props.sum;
    return "\n    <div class=\"".concat(styles.box__block, " ").concat(styles.box__disable, "\">\n      <div class=\"").concat(styles.box__shadow, "\">\n        <img src=\"").concat(ShadowSrc, "\" alt=\"\" />\n      </div>\n      <div class=\"").concat(styles.box__back, "\">\n        <img src=\"").concat(BoxBackSrc, "\" alt=\"\" />\n      </div>\n      <div class=\"").concat(styles.box__glow__under, "\">\n        <img src=\"").concat(GlowBoxSrc, "\" alt=\"\" />\n      </div>\n      <div class=\"").concat(styles.box__sum, "\" data-text=\"").concat(sum, "\">\n        <span>").concat(sum, "</span>\n      </div>\n      <div class=\"").concat(styles.box__front, "\">\n        <img src=\"").concat(BoxFrontSrc, "\" alt=\"\" />\n      </div>\n      <div class=\"").concat(styles.box__top, "\">\n        <img src=\"").concat(BoxTopSrc, "\" alt=\"\" />\n      </div>\n      <div class=\"").concat(styles.box__glow__k, "\">\n        <img src=\"").concat(GlowKSrc, "\" alt=\"\" />\n      </div>\n    </div>\n  ");
};
